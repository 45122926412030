import logo from './logo.svg';
import './App.css';
import { getAuth, signInWithPopup, GoogleAuthProvider, signInWithRedirect, OAuthProvider } from "firebase/auth";
import { Divider, List, Card, Table, Typography, InputNumber, Input, Layout, Menu, Drawer, Button, Radio, Space } from "antd";
import firebaseApp from "./Firebase";

const provider = new GoogleAuthProvider();
provider.addScope('https://www.googleapis.com/auth/calendar');

const microsoftProvider = new OAuthProvider('microsoft.com');

const appleProvider = new OAuthProvider('apple.com');
appleProvider.addScope('email');
appleProvider.addScope('name');

let googleAccessToken = "";
/*
const listEvents = (auth) => {
  const calendar = google.calendar({version: 'v3', auth});
  calendar.events.list({
    calendarId: 'primary',
    timeMin: (new Date()).toISOString(),
    maxResults: 10,
    singleEvents: true,
    orderBy: 'startTime',
  }, (err, res) => {
    if (err) return console.log('The API returned an error: ' + err);
    const events = res.data.items;
    if (events.length) {
      console.log('Upcoming 10 events:');
      events.map((event, i) => {
        const start = event.start.dateTime || event.start.date;
        console.log(`${start} - ${event.summary}`);
      });
    } else {
      console.log('No upcoming events found.');
    }
  });
}
*/

const loginGmail = () => { 
  const auth = getAuth();
  auth.languageCode = 'it';
  provider.setCustomParameters({
    'login_hint': 'user@example.com'
  });

  signInWithPopup(auth, provider)
  .then((result) => {
    // This gives you a Google Access Token. You can use it to access the Google API.
    const credential = GoogleAuthProvider.credentialFromResult(result);
    const token = credential.accessToken;
    googleAccessToken = token;
    console.log(googleAccessToken);
    // The signed-in user info.
    const user = result.user;
    console.log(user);
    // ...
  }).catch((error) => {
    // Handle Errors here.
    const errorCode = error.code;
    const errorMessage = error.message;
    console.log(error);
    // The email of the user's account used.
    const email = error.email;
    // The AuthCredential type that was used.
    const credential = GoogleAuthProvider.credentialFromError(error);

  });

}


//https://portal.azure.com/#blade/Microsoft_AAD_IAM/ActiveDirectoryMenuBlade/RegisteredApps
//https://portal.azure.com/#blade/Microsoft_AAD_RegisteredApps/ApplicationMenuBlade/Overview/quickStartType//sourceType/Microsoft_AAD_IAM/appId/172bb352-caa4-480a-9b1b-6e12a28c0e71/objectId/59d1bcd0-7c85-4b0d-a196-580029ca86d4/isMSAApp//defaultBlade/Overview/appSignInAudience/AzureADandPersonalMicrosoftAccount/servicePrincipalCreated/true

const loginMicrosoft = () => { 
  const auth = getAuth();
  auth.languageCode = 'it';
  microsoftProvider.setCustomParameters({
    // Force re-consent.
    prompt: 'consent',
    // Target specific email with login hint.
    login_hint: 'user@firstadd.onmicrosoft.com'
  });

  signInWithPopup(auth, provider)
  .then((result) => {
    // This gives you a Google Access Token. You can use it to access the Google API.
    const credential = OAuthProvider.credentialFromResult(result);
    const accessToken = credential.accessToken;
    const idToken = credential.idToken;    
    googleAccessToken = idToken;
    console.log(googleAccessToken);
    // The signed-in user info.
    const user = result.user;
    console.log(user);
    // ...
  }).catch((error) => {
    // Handle Errors here.
    console.log(error);
  });

}



const loginApple = () => { 
  const auth = getAuth();
  provider.setCustomParameters({
    // Localize the Apple authentication screen in French.
    locale: 'fr'
  });

  signInWithPopup(auth, provider)
  .then((result) => {
    // This gives you a Google Access Token. You can use it to access the Google API.
    const user = result.user;

    // Apple credential
    const credential = OAuthProvider.credentialFromResult(result);
    const accessToken = credential.accessToken;
    const idToken = credential.idToken;
    googleAccessToken = idToken;
    console.log(googleAccessToken);
    // The signed-in user info.
    
    // ...
  }).catch((error) => {
    // Handle Errors here.
    console.log(error);
    const credential = OAuthProvider.credentialFromError(error);


  });

}

function App() {

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
      
        <a
          className="App-link"
          href="/login"
          target="_blank"
          rel="noopener noreferrer"
        >
          Signup
        </a>

      </header>
    </div>
  );
}

export default App;
