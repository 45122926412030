import logo from './logo.svg';
import './App.css';
import "antd/dist/antd.css";
import React from "react";
import { Layout, Space, Modal, Button, Menu, Breadcrumb, Calendar, Select, Radio, Col, Row, message, Typography, Form, Input } from 'antd';
import Paper, { Path, Point, PointText, Rectangle, Size } from 'paper';
import moment from 'moment-timezone';
import { UserOutlined, SettingOutlined, PieChartOutlined } from '@ant-design/icons';

const { Header, Content, Footer } = Layout;


class CalendarFooter extends React.Component {

    openUrl = (targetUrl) =>
    {
        window.location.href = window.location.origin + targetUrl;
    }
    openExternalUrl = (targetUrl) =>
    {
        window.location.href = targetUrl;
    }

    render() {
        return (
            <Footer style={{ textAlign: 'center' }}>Copyright © Techiehug. All Rights Reserved</Footer>
        )
    }
}
export default CalendarFooter;
