import logo from './logo.svg';
import './App.css';
import "antd/dist/antd.css";
import React from "react";
import firebaseApp from "./Firebase";
import { getAuth, onAuthStateChanged, GoogleAuthProvider, signInWithRedirect, OAuthProvider } from "firebase/auth";
import { Layout, Space, Modal, Button, Menu, Breadcrumb, Checkbox, Calendar, Select, Radio, Col, Row, message, Typography, Form, Input } from 'antd';
import Paper, { Path, Point, PointText, Rectangle, Size } from 'paper';
import moment from 'moment-timezone';
import CalendarHeader from "./CalendarHeader"
import CalendarFooter from "./CalendarFooter"

//https://github.com/kucukkanat/LocalDB
import LocalDB from 'local-db'


const canvasWidth = 1090;
const millisInMinute = 60000;
const timelineWidth = 40;
const secondTimelineWidth = 80;
const secondTimelineStart = 10;
const weekBlockWidth = 150;
const fullDayHeight = 2400;
const defaultDaySlice = 900000;
const timeBetweenBooking = 100000; //todo
let timezoneRuler = {};

const { Header, Content, Footer } = Layout;
var calendarPros = {
    "start_at": '2021-10-28T09:00:00-07:00',
    "end_at": '2021-10-28T10:00:00-07:00',
    "name": "Testing",
    "attendees": [],
    "optional-attendees": [],
    "description": "Testing",
    "conference": "Chennai",
    "location": "Vadapalani",
    "payment": "Cool"
};

var calendarProps = new LocalDB('calendar')

const { Option } = Select;

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};


const timezoneSelect = [];
const timezoneList = moment.tz.names();
for (let i = 0; i < timezoneList.length; i++) {
    timezoneSelect.push(<Option key={timezoneList[i]}>{timezoneList[i]}</Option>);
}

const form = {};

const hoursMap = ['one', 'two', 'three'];

class CalendarAuthorizerPost extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            counter: 0,
            booking: false,
            visible: false,
            screenWidth: 0,
            screenHeight: 30000,
            slicerMillis: defaultDaySlice,
            earlyBookings: []
        };
    }



    componentDidMount()
    {
        const firebaeAuth = getAuth();
        onAuthStateChanged(firebaeAuth, (user) => {
            console.log(user);
            if (user) {
                this.setState({ "currentUserProps": user });
                if(this.props.match.params["authorizpostid"]!=undefined)
                {
                    console.log("this.props.match.params.authorizpostid", this.props.match.params["authorizpostid"]);
                    firebaseApp.linkRedirectCodeWithUser(user.uid, this.props.match.params.authorizpostid);
                }
            } else {
                console.log("Unauthorised user");
            }
          });
    }


    render() {
        const { register, handleSubmit, errors } = this.props;

        return (
            <Layout className="layout" style={{ backgroundColor: "#FFFFFF", minHeight: "100vh" }}>
                <CalendarHeader />

                <Content style={{ width: "980px", margin: "0 auto" }}>
                    <div style={{ marginLeft: "50pt" }} className="site-layout-content">


                    </div>
                </Content>
                <CalendarFooter />
            </Layout>
        )
    }
}

export default CalendarAuthorizerPost;