import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Test from './Test';
import CalendarPlanner from './CalendarPlanner';
import CalendarAuthorizer from "./CalendarAuthorizer"
import CalendarAuthorizerPost from "./CalendarAuthorizerPost"
import LayoutTest from './LayoutTest';
import Login from './Login';
import Setting from './Setting';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route } from 'react-router-dom';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter basename="/">
        <Route exact path='/' component={App} />
        <Route exact path='/calendar/:calenderid' component={CalendarPlanner} />
        <Route exact path='/calendar-authorizer' component={CalendarAuthorizer} />
        <Route exact path='/calendar-authorizer-post/:authorizpostid' component={CalendarAuthorizerPost} />
        <Route exact path='/test' component={Test} />
        <Route exact path='/layout' component={LayoutTest} />
        <Route exact path='/settings' component={Setting} />
        <Route exact path='/login' component={Login} />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
