import './App.css';
import "antd/dist/antd.css";
import React from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { Layout, Space, Modal, Button, Menu, Breadcrumb, Calendar, Select, Radio, Col, Row, message, Typography, Form, Input } from 'antd';
import Paper, { Path, Point, PointText, Rectangle, Size } from 'paper';
import moment from 'moment-timezone';
import { UserOutlined, SettingOutlined, CalendarOutlined } from '@ant-design/icons';

const { Header, Content, Footer } = Layout;


class CalendarHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSignedUser: false
        };
    }

    openUrl = (targetUrl) => {
        window.location.href = window.location.origin + targetUrl;
    }
    openExternalUrl = (targetUrl) => {
        window.location.href = targetUrl;
    }


    componentDidMount() {

        const firebaeAuth = getAuth();
        onAuthStateChanged(firebaeAuth, (currentUser) => {
            if (currentUser) {
                this.setState({ "isSignedUser": true });
            }
        });
    }

    render() {
        return (
            <Header style={{ marginBottom: "10px" }}>
                {(this.state.isSignedUser) ?
                    <Row>
                        <Col justify="left" span={14}>
                            <div id="appName"><h1 style={{ color: "white" }}>Calendar Planner</h1></div>
                        </Col>
                        <Col justify="end" span={8}>
                            <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['log-in']}>
                                <Menu.Item onClick={(e) => this.openUrl("/settings")} key="settings"><SettingOutlined /> Settings</Menu.Item>
                                <Menu.Item onClick={(e) => this.openExternalUrl("https://sites.google.com/techiehug.com/calendarplanner/home")} key="help">Help</Menu.Item>
                            </Menu>
                        </Col>
                    </Row>
                :
                    <Row>
                        <Col justify="left" span={14}>
                            <div id="appName"><h1 style={{ color: "white" }}>CalendarX Planner</h1></div>
                        </Col>
                        <Col justify="end" span={8}>
                            <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['log-in']}>
                                <Menu.Item onClick={(e) => this.openExternalUrl("https://sites.google.com/techiehug.com/calendarplanner/home")} key="help">Help</Menu.Item>
                                <Menu.Item onClick={(e) => this.openUrl("/login")} key="log-in">Log in</Menu.Item>
                            </Menu>
                        </Col>
                    </Row>
                }
            </Header>
        )
    }
}
export default CalendarHeader;
