import logo from './logo.svg';
import './App.css';
import "antd/dist/antd.css";
import React from "react";
import firebaseApp from "./Firebase";
import { Layout, Space, Modal, Button, Menu, Breadcrumb, Checkbox, Calendar, Select, Radio, Col, Row, message, Typography, Form, Input } from 'antd';
import { getAuth, onAuthStateChanged, signInWithPopup, GoogleAuthProvider, signInWithRedirect, OAuthProvider } from "firebase/auth";

import Paper, { Path, Point, PointText, Rectangle, Size } from 'paper';
import moment from 'moment-timezone';
import { UserOutlined, SettingOutlined, PieChartOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd/es/form';
import CalendarHeader from "./CalendarHeader"
import CalendarFooter from "./CalendarFooter"

//https://github.com/kucukkanat/LocalDB
import LocalDB from 'local-db'

const googleProvider = new GoogleAuthProvider();
googleProvider.addScope('https://www.googleapis.com/auth/calendar');

const microsoftProvider = new OAuthProvider('microsoft.com');
microsoftProvider.addScope('Calendars.ReadWrite');

const appleProvider = new OAuthProvider('apple.com');
appleProvider.addScope('email');
appleProvider.addScope('name');


let googleAccessToken = "";
let microsoftAccessToken = "";
let appleAccessToken = "";


var monthLabels = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
var weekLabels = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
const attendees = [];
const optionalAttendees = [];
const timezoneFontProps = { fontSize: 12, fontFamily: "Arial Black" }
const calendarMonthFontProps = { fontSize: 20, fontFamily: "Arial Black" }
const calendarHourRuleProps = { strokeColor: '#696969', strokeWidth: 1 };

const canvasWidth = 1090;
const millisInMinute = 60000;
const timelineWidth = 40;
const secondTimelineWidth = 80;
const secondTimelineStart = 10;
const weekBlockWidth = 150;
const fullDayHeight = 2400;
const defaultDaySlice = 900000;
const timeBetweenBooking = 100000; //todo
let timezoneRuler = {};

const { Header, Content, Footer } = Layout;
var calendarPros = {
    "start_at": '2021-10-28T09:00:00-07:00',
    "end_at": '2021-10-28T10:00:00-07:00',
    "name": "Testing",
    "attendees": [],
    "optional-attendees": [],
    "description": "Testing",
    "conference": "Chennai",
    "location": "Vadapalani",
    "payment": "Cool"
};

var calendarProps = new LocalDB('calendar')

const { Option } = Select;

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};


const timezoneSelect = [];
const timezoneList = moment.tz.names();
for (let i = 0; i < timezoneList.length; i++) {
    timezoneSelect.push(<Option key={timezoneList[i]}>{timezoneList[i]}</Option>);
}

const form = {};

const hoursMap = ['one', 'two', 'three'];

class CalendarAuthorizer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            counter: 0,
            booking: false,
            visible: false,
            screenWidth: 0,
            screenHeight: 30000,
            slicerMillis: defaultDaySlice,
            earlyBookings: []
        };
    }



    loginGmail = () => {
        window.location.href="https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?client_id=598724566440-6i7u41lq5laufklau2umcnspllb4b92b.apps.googleusercontent.com&redirect_uri=https://asia-east2-plannerhug.cloudfunctions.net/google-auth-redirect&scope=https://www.googleapis.com/auth/calendar+https://www.googleapis.com/auth/userinfo.profile&response_type=code&access_type=offline&prompt=consent"
    }

    //https://portal.azure.com/#blade/Microsoft_AAD_IAM/ActiveDirectoryMenuBlade/RegisteredApps
    //https://portal.azure.com/#blade/Microsoft_AAD_RegisteredApps/ApplicationMenuBlade/Overview/quickStartType//sourceType/Microsoft_AAD_IAM/appId/172bb352-caa4-480a-9b1b-6e12a28c0e71/objectId/59d1bcd0-7c85-4b0d-a196-580029ca86d4/isMSAApp//defaultBlade/Overview/appSignInAudience/AzureADandPersonalMicrosoftAccount/servicePrincipalCreated/true
    //59d1bcd0-7c85-4b0d-a196-580029ca86d4  
    //6TJKMh4aXHfWtklaI30xRo8w javascript
    //ypd7Q~lKrdWpn6dIzZA3.Dy5LaW9o3OQYnLqa    secret 45e7458e-195a-4736-a433-637ed54ae5a0

    loginMicrosoft = () => {
        const auth = getAuth();
        auth.languageCode = 'en';
        microsoftProvider.setCustomParameters({ prompt: 'consent' });

        signInWithPopup(auth, microsoftProvider)
            .then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = OAuthProvider.credentialFromResult(result);
                const accessToken = credential.accessToken;
                const idToken = credential.idToken;
                microsoftAccessToken = idToken;
                console.log(microsoftAccessToken);
                // The signed-in user info.
                const user = result.user;
                console.log(user);
                // ...
            }).catch((error) => {
                // Handle Errors here.
                console.log(error);
            });

    }

    toJson = (givenObject) => {
        return JSON.parse(JSON.stringify(givenObject));
    }


    loginApple = () => {
        const auth = getAuth();
        appleProvider.setCustomParameters({
            // Localize the Apple authentication screen in French.
            locale: 'fr'
        });

        signInWithPopup(auth, appleProvider)
            .then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const user = result.user;

                // Apple credential
                const credential = OAuthProvider.credentialFromResult(result);
                const accessToken = credential.accessToken;
                const idToken = credential.idToken;
                appleAccessToken = idToken;
                console.log(appleAccessToken);

            }).catch((error) => {
                // Handle Errors here.
                console.log(error);
                const credential = OAuthProvider.credentialFromError(error);


            });

    }

    timezoneChangeHandle = (userPreferenceHours) => {
        if (userPreferenceHours.length == 0) return;
        timezoneRuler = {};
        userPreferenceHours.forEach(eachTimezone => {
            const selectedOffset = moment().tz(eachTimezone).format('lll');
            const browserOffset = moment().tz(moment.tz.guess()).format('lll');
            const diffOffset = -(new Date(browserOffset) - new Date(selectedOffset)) / millisInMinute;
            console.log(selectedOffset, browserOffset, diffOffset);
            timezoneRuler[diffOffset] = { "abbr": eachTimezone };
        });
        console.log(timezoneRuler);
        this.buildCalendar();
    }

    onFinish = () => {
        message.success('Submit success!');
    };

    onFinishFailed = () => {
        message.error('Submit failed!');
    };


    handleTimeSlicer = (e) => {
        console.log(e);
        this.setState({ "slicerMillis": e.target.value });
        this.buildCalendar(e.target.value);
    }


    onMouseDown = (event) => {
        console.log(event);
        this.setState({ visible: true });
        console.log('You pressed the mouse!');
    }

    onMouseDrag = (event) => {
        console.log('You dragged the mouse!');
    }

    utcMillisToBrowser(utcMillis) {
        return (utcMillis - (new Date().getTimezoneOffset() * millisInMinute));
    }

    browserMillisToUtc(browserMillis) {
        return (browserMillis + (new Date().getTimezoneOffset() * millisInMinute));
    }

    onMouseUp = (event) => {
        calendarPros["start"] = this.browserMillisToUtc(event.target.data["start"]);
        calendarPros["start_at"] = new Date(calendarPros["start"]);
        calendarPros["end"] = this.browserMillisToUtc(event.target.data["end"]);
        calendarPros["end_at"] = new Date(calendarPros["end"])
        console.log(calendarPros);
        this.setState({ visible: true });
        console.log(this.state.visible);
        this.setState({ counter: new Date().getTime() });
        console.log('You released the mouse!');
    }

    showModal = () => {
        this.setState({ visible: true });
    };

    formValueChange = (e) => {
        console.log(e.target.name);
        calendarPros[e.target.name] = e.target.value;
    }
    addAttendees = (attendees, optional) => {
        calendarPros["attendees"] = [];
        attendees.forEach(eachAttendee => {
            calendarPros["attendees"].push({ "email": eachAttendee, "optional": optional });
        });
    }
    addOptionalAttendees = (optionalAttendees, optional) => {
        calendarPros["optional-attendees"] = [];
        optionalAttendees.forEach(eachAttendee => {
            calendarPros["optional-attendees"].push({ "email": eachAttendee, "optional": optional });
        });
        console.log(calendarPros["optional-attendees"]);
    }

    createCalendar = (e) => {
        this.setState({ booking: true });
        calendarPros["attendees"] = [...calendarPros["attendees"], ...calendarPros["optional-attendees"]];
        delete calendarPros["optional-attendees"];
        console.log(calendarPros);
        const savePromise = firebaseApp.saveBooking(calendarPros);
        //savePromise.then(result => console.log(result));
        console.log(savePromise);
        calendarPros["optional-attendees"] = [];
        //this.setState({ visible: false });
    };


    cancelNewCalendar = () => {
        this.setState({ visible: false });
    };

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions)
    }

    updateWindowDimensions() {
        console.log(window.innerWidth);
        this.setState({ screenWidth: window.innerWidth });
    }

    processEarlyBooking = (queryPromise) => {
        const earlyBookings = [];
        queryPromise.then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                var calenProps = doc.data();
                calenProps["start"] = this.utcMillisToBrowser(calenProps["start"]);
                calenProps["end"] = this.utcMillisToBrowser(calenProps["end"]);
                earlyBookings.push(calenProps);
            });
            this.setState({ "earlyBookings": earlyBookings });
            console.log(earlyBookings);
            this.buildCalendar();
        });
    }


    componentDidMount() {

        const firebaeAuth = getAuth();
        onAuthStateChanged(firebaeAuth, (user) => {
            console.log(user);
            if (user) {
                console.log("Authorised user");
            } else {
                console.log("Unauthorised user");
            }
          });

        console.log(calendarProps.query({ username: { $eq: "kucukkanat" } }));

    }

    onFinish = () => {

    }

    onFinishFailed = () => {

    }


    render() {
        const { register, handleSubmit, errors } = this.props;

        return (
            <Layout className="layout" style={{ backgroundColor: "#FFFFFF", minHeight: "100vh" }}>
                <CalendarHeader />

                <Content style={{ width: "980px", margin: "0 auto" }}>
                    <div style={{ marginLeft: "50pt" }} className="site-layout-content">

                        <Button onClick={(e) => this.loginGmail()} icon={<i className="fas fa-plus"></i>} size={5} >Gmail</Button>
                        <Button onClick={(e) => this.loginMicrosoft()} icon={<i className="fas fa-plus"></i>} size={5} >Microsoft</Button>
                        <Button onClick={(e) => this.loginApple()} icon={<i className="fas fa-plus"></i>} size={5} >Apple</Button>

                    </div>
                </Content>
                <CalendarFooter />
            </Layout>
        )
    }
}

export default CalendarAuthorizer;