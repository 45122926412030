import logo from './logo.svg';
import './App.css';
import "antd/dist/antd.css";
import React from "react";
import firebaseApp from "./Firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { Avatar, Image, Layout, Space, Modal, Button, Menu, Breadcrumb, Calendar, Select, Radio, Col, Row, message, Typography, Form, Input } from 'antd';
import Paper, { Path, Point, PointText, Rectangle, Size } from 'paper';
import moment from 'moment-timezone';
import { UserOutlined, CalendarOutlined, PieChartOutlined } from '@ant-design/icons';
import CalendarHeader from "./CalendarHeader";
import CalendarFooter from "./CalendarFooter";
import LocalDB from 'local-db';
import axios from "axios";

let currentUserId = null;
let currentCalendarId = null;
let weekOfDay = new Date().getDate();
const { Header, Content, Footer } = Layout;
const blockMargin = 2;
var monthLabels = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
var weekLabels = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
const attendees = [];
const optionalAttendees = [];
const timezoneFontProps = { fontSize: 12, fontFamily:"Arial Black" }
const calendarMonthFontProps = { fontSize: 20, fontFamily:"Arial Black" }
const calendarHourRuleProps = {strokeColor:'#696969', strokeWidth:1};
let workingHours = {
    "0" : {"start":540, "end":1080, "work":0},
    "1" : {"start":540, "end":1080},
    "2" : {"start":540, "end":1080},
    "3" : {"start":540, "end":1080},
    "4" : {"start":320, "end":1080},
    "5" : {"start":320, "end":1080},
    "6" : {"start":240, "end":700, "work":0}
};

const canvasWidth = 1200;
const millisInMinute = 60000;
const timelineWidth = 40;
const secondTimelineWidth = 80;
const secondTimelineStart = 10;
const weekBlockWidth = 150;
const fullDayHeight = 2400;
let defaultDaySlice = 900000;
const timeBetweenBooking = 100000; //todo
let timezoneRuler = {};
let calenProps = {};

var calendarProps = new LocalDB('calendar')

var calendarPros = {
    "start_at": '2021-10-28T09:00:00-07:00',
    "end_at": '2021-10-28T10:00:00-07:00',
    "name": "Testing",
    "attendees": [],
    "optional-attendees": [],
    "description": "Description is not given",
    "conference": "Conference Details are not specified.",
    "location": "Location is not specified",
    "payment": "Cool",
    "type":"REQUEST",
    "sequence":1,
    "status":"CONFIRMED",
    "organizer":"calendar-planner@techiehug.com"
};


const { Option } = Select;

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};


const timezoneSelect = [];
const timezoneList = moment.tz.names();
for (let i = 0; i < timezoneList.length; i++) {
    timezoneSelect.push(<Option key={timezoneList[i]}>{timezoneList[i]}</Option>);
}

const form = {};

const hoursMap = ['one', 'two', 'three'];

class CalendarPlanner extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            counter: 0,
            booking: false,
            visible: false,
            screenWidth: 0,
            screenHeight: 30000,
            slicerMillis: defaultDaySlice,
            earlyBookings: [],
            currentUserProps: {  },
            isCalendarActive: true
        };
    }

    formRef = React.createRef();

    timezoneChangeHandle = (userPreferenceHours) => {
        if (userPreferenceHours.length == 0) return;
        timezoneRuler = {};
        userPreferenceHours.forEach(eachTimezone => {
            const selectedOffset = moment().tz(eachTimezone).format('lll');
            const browserOffset = moment().tz(moment.tz.guess()).format('lll');
            const diffOffset = -(new Date(browserOffset) - new Date(selectedOffset))/millisInMinute;
            console.log(selectedOffset, browserOffset, diffOffset);
            timezoneRuler[diffOffset] = { "abbr": eachTimezone };
        });
        console.log(timezoneRuler);
        this.buildCalendar();
    }

    onFinish = () => {
        message.success('Submit success!');
    };

    onFinishFailed = () => {
        message.error('Submit failed!');
    };

    onFill = () => {
        form.setFieldsValue({
            url: 'https://taobao.com/',
        });
    };

    handleTimeSlicer = (e) => {
        console.log(e);
        this.setState({ "slicerMillis": e.target.value });
        this.buildCalendar(e.target.value);
    }


    onMouseDown = (event) => {
        console.log(event);
        this.setState({ visible: true });
        console.log('You pressed the mouse!');
    }

    onMouseDrag = (event) => {
        console.log('You dragged the mouse!');
    }

    utcMillisToBrowser(utcMillis) {
        return (utcMillis - (new Date().getTimezoneOffset() * millisInMinute));
    }

    toBrowserByTimeZoneAndMillis(timeZone, utcMillis) {
        return (utcMillis - (new Date().getTimezoneOffset() * millisInMinute));
    }

    browserMillisToUtc(browserMillis) {
        return (browserMillis + (new Date().getTimezoneOffset() * millisInMinute));
    }

    onMouseUp = (event) => {
        calendarPros["start"] = this.browserMillisToUtc(event.target.data["start"]);
        calendarPros["start_at"] = new Date(calendarPros["start"]);
        calendarPros["end"] = this.browserMillisToUtc(event.target.data["end"]);
        calendarPros["end_at"] = new Date(calendarPros["end"])
        console.log(calendarPros);
        this.setState({ visible: true });
        console.log(this.state.visible);
        this.setState({ counter: new Date().getTime() });
        console.log('You released the mouse!');
    }

    showModal = () => {
        this.setState({ visible: true });
    };

    formValueChange = (e) => {
        console.log(e.target.name);
        calendarPros[e.target.name] = e.target.value;
    }
    addAttendees = (attendees, optional) => {
        calendarPros["attendees"] = [];
        attendees.forEach(eachAttendee => {
            calendarPros["attendees"].push({ "email": eachAttendee, "optional": optional });
        });
    }
    addOptionalAttendees = (optionalAttendees, optional) => {
        calendarPros["optional-attendees"] = [];
        optionalAttendees.forEach(eachAttendee => {
            calendarPros["optional-attendees"].push({ "email": eachAttendee, "optional": optional });
        });
        console.log(calendarPros["optional-attendees"]);
    }

    createCalendar = (e) => {
        this.setState({ booking: true });
        calendarPros["attendees"] = [...calendarPros["attendees"], ...calendarPros["optional-attendees"]];
        delete calendarPros["optional-attendees"];
        console.log(calendarPros);
        calendarPros["timezone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;
        calendarPros["calendar_id"] = currentCalendarId;
        calendarPros["user_id"] = currentUserId;
        delete calendarPros[""]; //todo why this dummy
        const savePromise = firebaseApp.saveBooking(calendarPros);
        //savePromise.then(result => console.log(result));
        //console.log(savePromise);
        calendarPros["optional-attendees"] = [];
        this.setState({ visible: false });
    };


    cancelNewCalendar = () => {
        this.setState({ visible: false });
    };

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions)
    }

    updateWindowDimensions() {
        console.log(window.innerWidth);
        this.setState({ screenWidth: window.innerWidth });
    }

    calendarExistencePromiseProcess = (queryPromise) =>
    {
        const userCalendars = [];
        var isCalendarActive = false;
        queryPromise.then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                calenProps = doc.data();
                userCalendars.push(calenProps);
                isCalendarActive = true
            });
            let slicerMillis = ((calenProps["slicer_millis"]) ? calenProps["slicer_millis"] : defaultDaySlice);
            this.setState({ "isCalendarActive": isCalendarActive, "slicerMillis": slicerMillis });
            console.log(userCalendars);
        });
    }
    

    findCalendarByIdPromiseProcess = (queryPromise) =>
    {
        var isCalendarActive = false;
        queryPromise.then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                calenProps = doc.data();
                console.log(calenProps);
                isCalendarActive = true;
                workingHours = calenProps["time_grid"];
                currentCalendarId = calenProps["calendar_id"];
            });
            defaultDaySlice = ((calenProps["slicer_millis"]) ? calenProps["slicer_millis"] : defaultDaySlice);
            this.setState({ "isCalendarActive": isCalendarActive });
            this.buildCalendar(this.state.slicerMillis);
        });
    }



    processEarlyBooking = (queryPromise) => {
        const earlyBookings = [];
        queryPromise.then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                var calenProps = doc.data();
                calenProps["start"] = this.utcMillisToBrowser(calenProps["start"]);
                calenProps["end"] = this.utcMillisToBrowser(calenProps["end"]);
                earlyBookings.push(calenProps);
            });
            this.setState({ "earlyBookings": earlyBookings });
            console.log(earlyBookings);
            this.buildCalendar();
        });
    }

    goWeekOf = () =>
    {
        var date = new Date();
        date.setDate(weekOfDay);
        return date;
    }


    buildCalendar = (slicerMillis) => {
        Paper.project.activeLayer.removeChildren();


        if (slicerMillis == null) slicerMillis = defaultDaySlice;
        var currentUtcTime = moment.utc();

        var userTimezone = moment.tz.zone(moment.tz.guess()).utcOffset(currentUtcTime);
        console.log(userTimezone);


        let verticalSkip = (fullDayHeight / 24);
        const timeLabelVerticalSkip = 10;


        var currentUtcTime = moment.utc();
        var userTimezone = moment.tz.zone(moment.tz.guess()).utcOffset(currentUtcTime);

        console.log(userTimezone / 60);
        console.log("slicerMillis--", slicerMillis);


        var currentPosition = 130;

        //set the UTC
        let browserStartTime = this.goWeekOf();
        console.log("browserStartTime", browserStartTime);
        browserStartTime.setHours(0, 0, 0, 0);

        var nextDay = new Date(browserStartTime);
        var slicerTimeIncrement = 0;

        var currentSliceY = currentPosition;
        const slicerBy = (86400000 / slicerMillis);
        console.log("slicerBy-", slicerBy);

        let verticalQuaterSkip = (fullDayHeight / slicerBy);
        let horizontalStart = 0;
        let horizontalSkip = 40;
        let horizontalBlockCount = 0;

        console.log("verticalSkip-", verticalSkip);

        var millisSinceDayStart = (new Date().getTime() - browserStartTime.getTime());

        let hourCurrentPosition = currentPosition;
        for (var hour = 0; hour < 25; hour++) {
            var path = new Path(calendarHourRuleProps);
            path.add(new Point(horizontalStart, hourCurrentPosition));
            path.add(new Point(5000, hourCurrentPosition));

            //hour ruler - time label
            var text = new PointText(new Point(horizontalStart, hourCurrentPosition + timeLabelVerticalSkip));
            text.fillColor = 'black';
            var ampm = " AM";
            var hourString = hour;
            if(hour < 10) { hourString = "0" + hour + ""; }
            else if(hour > 21) { hourString = (hour-12) + ""; ampm = " PM";}
            else if(hour > 12) { hourString = "0" + (hour-12) + ""; ampm = " PM";}
            text.content = hourString + ":00" + ampm;

            hourCurrentPosition += verticalSkip;
        }

        currentPosition += verticalSkip;
        var quater = 0;
        var eachSliceMins = ((86400000/slicerBy)/60000);
        var currentMins = 0;

        let currentBrowserDigitalTime = 0;

        console.log("timezoneRuler", timezoneRuler);

        const timezoneRulerCount = Object.keys(timezoneRuler).length + 1;

        //week headers
        let weekHorizontalSkip = [secondTimelineStart + (secondTimelineWidth * (timezoneRulerCount)), secondTimelineStart + (secondTimelineWidth * (timezoneRulerCount) + 100)];
        //add go previous week icon
        var leftTriangle = new Path.RegularPolygon(new Point(weekHorizontalSkip[0]-20, 70), 3, -25);
        leftTriangle.fillColor = '#008B8B';
        leftTriangle.rotate(90);
        leftTriangle.onMouseDown = (event) => {
            console.log(event);
            weekOfDay = weekOfDay - 1;
            this.goWeekOf();
            this.buildCalendar();
        }

        for(var week = 0; week < 7; week++)
        {
            var text = new PointText(new Point(weekHorizontalSkip[0]+20, currentSliceY - 80));
            text.style = calendarMonthFontProps;
            text.content = monthLabels[nextDay.getMonth()];
            let dayOfWeek = nextDay.getDay();

            var text = new PointText(new Point(weekHorizontalSkip[0]+20, currentSliceY - 45));
            text.style = { fontFamily:"Arial Black", fontSize: 35 };
            if(workingHours[dayOfWeek]["work"]==0){ text.style = { fontFamily:"Arial Black", fontSize: 35, fillColor:"#CC0000" }; }
            text.content = ((nextDay.getDate() < 10) ? "0" + nextDay.getDate() : nextDay.getDate());

            var text = new PointText(new Point(weekHorizontalSkip[0]+20, currentSliceY - 20));
            text.style = calendarMonthFontProps;
            text.content = weekLabels[nextDay.getDay()];

            weekHorizontalSkip[0] = weekHorizontalSkip[0] + weekBlockWidth;
            weekHorizontalSkip[1] = weekHorizontalSkip[1] + weekBlockWidth;
            nextDay.setDate(nextDay.getDate() + 1);
        }//for 7 days


        //add go next week icon
        var rightTriangle = new Path.RegularPolygon(new Point(weekHorizontalSkip[0]-50, 70), 3, -25);
        rightTriangle.fillColor = '#008B8B';
        rightTriangle.rotate(270);
        rightTriangle.onMouseDown = (event) => {
            console.log(event);
            weekOfDay = weekOfDay + 1;
            this.goWeekOf();
            this.buildCalendar();
        }
        

        for (var slice = 0; slice < slicerBy; slice++) {
            hour = Math.floor(currentMins / 60);
            quater = (currentMins % 60);
            horizontalBlockCount = 1;
            var path = new Path();
            path.strokeColor = "#BEBEBE";
            path.strokeWidth = 0.3;

             //current time line in red color
            if ((millisSinceDayStart > slicerTimeIncrement) && millisSinceDayStart < (slicerTimeIncrement + slicerMillis)) {
                path.strokeWidth = 3;
                path.strokeColor = "#FF4500";
                console.log(millisSinceDayStart + "::" + slicerTimeIncrement);
            }

            path.add(new Point(horizontalStart, currentSliceY + verticalQuaterSkip));
            path.add(new Point(5000, currentSliceY + verticalQuaterSkip));

            var ampm = " AM";
            var hourString = hour;
            if(hour < 10) { hourString = "0" + hour + ""; }
            else if(hour > 21) { hourString = (hour-12) + ""; ampm = " PM";}
            else if(hour > 12) { hourString = "0" + (hour-12) + ""; ampm = " PM";}
            var quaterString = ((quater == 0) ? "00" : quater)
            currentBrowserDigitalTime = hourString + ":" + quaterString + ampm;

            //default timezone header
            if (hour == 0 && slice == 0) {
                var text = new PointText(new Point(horizontalStart, currentSliceY - 50));
                text.style = timezoneFontProps;
                text.content = moment.tz.guess().replace("/", "\n");
                text.rotate(270);
            }

            //additional timezone header
            for (var eachRulerOffset in timezoneRuler) {
                var userTimeHour = (hour + (eachRulerOffset / 60));
                console.log("userTimeHour 1 ", userTimeHour);
                if(userTimeHour < 0){ userTimeHour = (24 + userTimeHour); }
                console.log("userTimeHour 2 ", userTimeHour);
                var text = new PointText(new Point( (secondTimelineWidth * horizontalBlockCount), currentSliceY + timeLabelVerticalSkip));
                text.fillColor = 'black';
                
                var userTimeHourSplit = (userTimeHour+".0").split(".");
                userTimeHourSplit[1] = (userTimeHourSplit[1] * 1 * 6);
                userTimeHourSplit[0] = (userTimeHourSplit[0] * 1);
                var ampm = " AM";
                var quaterString = userTimeHourSplit[1] + (quater * 15);
                if(quaterString > 74) { userTimeHourSplit[0]++; quaterString = "15";}
                if(quaterString > 59) { userTimeHourSplit[0]++; quaterString = "00";}
                var userTimeString = userTimeHourSplit[0];
                if(userTimeHourSplit[0] > 24) { userTimeString = "0" + (userTimeHourSplit[0]-24); ampm = " PM";}
                else if(userTimeHourSplit[0] > 23) { userTimeString = (userTimeHourSplit[0]-12); ampm = " AM";}
                else if(userTimeHourSplit[0] > 21) { userTimeString = (userTimeHourSplit[0]-12); ampm = " PM";}
                else if(userTimeHourSplit[0] > 12) { userTimeString = "0" + (userTimeHourSplit[0]-12); ampm = " PM";}
                else if(userTimeHourSplit[0] < 10) { userTimeString = "0" + userTimeHourSplit[0]; }
                text.content = userTimeString +  "." + quaterString + ampm;


                if (hour == 0 && quater == 0) {
                    var text = new PointText(new Point( (secondTimelineWidth * horizontalBlockCount), currentSliceY - 50));
                    text.style = timezoneFontProps;
                    text.content = timezoneRuler[eachRulerOffset]["abbr"].replace("/", "\n")
                    text.rotate(-90);
                }
                horizontalBlockCount++;
                //todo canvasWidth = canvasWidth + secondTimelineWidth;
            }


            let weekHorizontalSkip = [secondTimelineStart + (secondTimelineWidth * (horizontalBlockCount)), secondTimelineStart + (secondTimelineWidth * (horizontalBlockCount) + 100)];
            for (var week = 0; week < 7; week++) {
                let nextDayMillis = nextDay.getTime();
                let dayOfWeek = nextDay.getDay()

                //add available slots
                const bookindData = { "start": nextDayMillis + slicerTimeIncrement, "end": nextDayMillis + slicerTimeIncrement + slicerMillis };
                var isAvailableSlot = true;
                var eventProps = { color: '#e9e9ff', };
                var earlyBookings = this.state.earlyBookings;
                for (var ri = 0; ri < earlyBookings.length; ri++) {
                    const startTime = (bookindData.start - timeBetweenBooking) + 1;
                    const endTime = (bookindData.end + timeBetweenBooking) - 1;
                    if (((endTime > earlyBookings[ri].start) && (endTime < earlyBookings[ri].end)) || ((endTime > earlyBookings[ri].start) && (endTime < earlyBookings[ri].end))) {
                        eventProps.color = "#A8A8A8";
                        isAvailableSlot = false;
                        ri = 10000000;
                    }
                }
                
                if((workingHours[dayOfWeek]!==undefined) && ((currentMins < workingHours[dayOfWeek]["start"]) || (currentMins > workingHours[dayOfWeek]["end"])))
                {
                    console.log("workingHours", dayOfWeek, "-",  currentMins, "-", workingHours[dayOfWeek]["start"]);
                    eventProps.color = "#A8A8A8";
                    isAvailableSlot = false;
                }

                var rectangle = new Rectangle(new Point(weekHorizontalSkip[0]+blockMargin, currentSliceY+blockMargin), new Point(weekHorizontalSkip[1]-blockMargin, (currentSliceY + verticalQuaterSkip)-blockMargin));
                var radius = new Size(5, 5);
                var path = new Path.Rectangle(rectangle, radius);
                path.fillColor = eventProps.color;
                path.strokeWidth = 0.3;
                path.strokeColor = 'black';
                path.data = bookindData;
                path.onMouseDown = (event) => {
                    this.setState({ visible: true });
                    this.onMouseUp(event);
                }

                if(workingHours[dayOfWeek]["work"]==0)
                {
                    isAvailableSlot = false;
                }


                //digital time (text) inside of each clicable blocks
                var digitalTime = new PointText(new Point(weekHorizontalSkip[0]+20, currentSliceY+16));
                digitalTime.content = currentBrowserDigitalTime;
                digitalTime.data = bookindData;
                digitalTime.onMouseDown = (event) => {
                    this.setState({ visible: true });
                    this.onMouseUp(event);
                }
                
                if(!isAvailableSlot)
                {
                    path.fillColor = "#A8A8A8";
                    path.onMouseDown = null;
                    digitalTime.onMouseDown = null;
                }

                                        
                nextDay.setDate(nextDay.getDate() + 1);
                weekHorizontalSkip[0] = weekHorizontalSkip[0] + weekBlockWidth;
                weekHorizontalSkip[1] = weekHorizontalSkip[1] + weekBlockWidth;
            } //week loop


            nextDay = new Date(browserStartTime);
            currentSliceY += verticalQuaterSkip;
            slicerTimeIncrement = slicerTimeIncrement + slicerMillis;

            currentMins = currentMins + eachSliceMins;
            console.log("currentMins", currentMins);
        } //each slice of hour


        //add bottom rule
        var path = new Path(calendarHourRuleProps);
        path.add(new Point(horizontalStart, currentPosition));
        path.add(new Point(5000, currentPosition));
        currentPosition += verticalSkip;


        currentSliceY = currentPosition;
        currentPosition += verticalSkip;

        // console.log(moment.tz(moment.tz.guess()).zoneAbbr());
        // console.log(moment.tz.guess());
        // console.log(moment.tz.names());
    }

    componentDidMount() {
        Paper.install(window);
        Paper.setup("paper-canvas");

        console.log(this.props.match.params);

        console.log(calendarProps.query({ "id": { $eq: "google" } }));

        const firebaeAuth = getAuth();
        onAuthStateChanged(firebaeAuth, (currentUser) => {
            console.log(currentUser);
            if (currentUser) {
                currentUserId = currentUser.uid;
                this.setState({ "currentUserProps": currentUser });
            } else {
                currentUserId = null;
            }
          });

        let currentCalendarFuzzyId = this.props.match.params.calenderid;

        window.addEventListener("resize", this.updateWindowDimensions());

        this.buildCalendar(this.state.slicerMillis);

        //var calendarExistencePromise = firebaseApp.calendarExistence(this.props.match.params.calendaruser);
        //this.calendarExistencePromiseProcess(calendarExistencePromise);

        var findCalendarByIdPromise = firebaseApp.findCalendarByFuzzyId(currentCalendarFuzzyId);
        this.findCalendarByIdPromiseProcess(findCalendarByIdPromise);


        //var queryPromise = firebaseApp.findBookings(1, 1);
        //this.processEarlyBooking(queryPromise);
        
        let userEventsUrl = `https://asia-east2-plannerhug.cloudfunctions.net/pull-user-events?calendarid=${this.props.match.params.calenderid}`;
        axios.get(userEventsUrl).then(response => {
            console.log(response);
            const earlyBookings = [];
            for(var i=0; i<response["data"]["data"]["items"].length; i++)
            {
                let calenProps = response["data"]["data"]["items"][i];
                var dateStart = moment.tz(calenProps["start"]["dateTime"], "YYYY-MM-DDTh:mm:ss", calenProps["start"]["timeZone"]).format();
                var dateEnd = moment.tz(calenProps["end"]["dateTime"], "YYYY-MM-DDTh:mm:ss", calenProps["start"]["timeZone"]).format();
                calenProps["start"] = this.utcMillisToBrowser(new Date(dateStart).getTime());
                calenProps["end"] = this.utcMillisToBrowser(new Date(dateEnd).getTime());
                earlyBookings.push(calenProps);
            }
            this.setState({ "earlyBookings": earlyBookings, "slicerMillis": 2700000 });
            console.log(earlyBookings);
            this.buildCalendar();
        });



    }

    render() {
        const { register, handleSubmit, errors } = this.props;

        return (
            <Layout className="layout" style={{ minHeight: "100vh" }} >
                <CalendarHeader/>

                <Content style={{width: "1200px", margin: "0 auto"}}>
                    <div className="site-layout-content" style={((this.state.isCalendarActive) ? {display:"block"} : {display:"none"} ) }>
                        <Row>
                            <Col span={12}>
                                {/*<h4>Dice the day</h4>
                                <Radio.Group value="10" onChange={this.handleTimeSlicer}>
                                    <Radio.Button value="900000">15 Mins</Radio.Button>
                                    <Radio.Button value="1800000">30 Mins</Radio.Button>
                                    <Radio.Button value="2700000">45 Mins</Radio.Button>
                                    <Radio.Button value="3600000">1 Hour</Radio.Button>
                                    <Radio.Button value="7200000">2 Hours</Radio.Button>
                                    <Radio.Button value="10800000">3 Hours</Radio.Button>
                                </Radio.Group>
                                */}
                                <Select style={{ width:"490px" }} placeholder="Choose Additional Timezones" mode="multiple" allowClear onChange={this.timezoneChangeHandle} tokenSeparators={[';']}>{timezoneSelect}</Select>
                            </Col>
                            <Col span={12}>
                                <h1><Avatar style={{ backgroundColor: '#87d068' }} icon={<CalendarOutlined />} /> {this.state.currentUserProps.displayName}</h1>
                            </Col>
                        </Row>



                        <canvas id='paper-canvas' resize='true' width={canvasWidth} height="2800" />


                        <Modal
                            visible={this.state.visible}
                            title="New Calendar"
                            onOk={this.createCalendar}
                            onCancel={this.cancelNewCalendar}
                            footer={[
                                <Button key="back" onClick={this.cancelNewCalendar}>cancel</Button>,
                                <Button key="submit" type="primary" loading={this.state.booking} onClick={(e) => this.createCalendar(e)}>Submit</Button>
                            ]}>

                            <Form {...layout} name="control-hooks" ref={this.formRef}>
                                <Form.Item name="name" label="name" rules={[{ required: true }]}>
                                    <Input name="name" onChange={(e) => this.formValueChange(e)} />
                                </Form.Item>
                                <Form.Item label="Attendees" name="attendees">
                                    <Select mode="tags" style={{ width: '100%' }} placeholder="Tags Mode" onChange={(e) => this.addAttendees(e, "no")}>{attendees}</Select>
                                </Form.Item>
                                <Form.Item label="Attendees (optional)" name="attendees_optional">
                                    <Select mode="tags" style={{ width: '100%' }} placeholder="Tags Mode" onChange={(e) => this.addOptionalAttendees(e, "yes")}>{optionalAttendees}</Select>
                                </Form.Item>
                                <Form.Item label="Description" name="description" onChange={(e) => this.formValueChange(e)}>
                                    <Input.TextArea />
                                </Form.Item>
                            </Form>
                        </Modal>

                    </div>
                </Content>

                <Content style={((this.state.isCalendarActive) ? {display:"none"} : {display:"block"} ) }>
                    Calendar is not available. May be the user could have deactivated it.
                </Content>

                <CalendarFooter/>
            </Layout>
        )
    }
}

export default CalendarPlanner;
