import logo from './logo.svg';
import './App.css';
import firebaseApp from "./Firebase";
import { Layout, Menu, Breadcrumb, Calendar, Select, Radio, Col, Row, Typography } from 'antd';
import React from "react";
import "antd/dist/antd.css";


const { Header, Content, Footer } = Layout;
const hoursMap = ['one', 'two', 'three'];

class LayoutTest extends React.Component {
    constructor() {
        super();
        this.state = {

        };

    }


    render() {
        return (
            <Layout className="layout" style={{ minHeight: "100vh" }}>
                <Header>
                    <div className="logo" />
                    <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['2']}>
                        {new Array(15).fill(null).map((_, index) => {
                            const key = index + 1;
                            return <Menu.Item key={key}>{`nav ${key}`}</Menu.Item>;
                        })}
                    </Menu>
                </Header>
                <Content style={{ padding: '0 50px' }}>
                    <Breadcrumb style={{ margin: '16px 0' }}>
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item>List</Breadcrumb.Item>
                        <Breadcrumb.Item>App</Breadcrumb.Item>
                    </Breadcrumb>
                    <div className="site-layout-content">Content</div>
                </Content>
                <Footer style={{ textAlign: 'center' }}>Copyright © Techiehug. All Rights Reserved</Footer>
            </Layout>
        )
    }
}

export default LayoutTest;
