import logo from './logo.svg';
import './App.css';
import "antd/dist/antd.css";
import React from "react";
import firebaseApp from "./Firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { Card, Layout, Space, Modal, Tabs, Button, Menu, Breadcrumb, Checkbox, Calendar, TimePicker, Select, Radio, Col, Row, message, Typography, Form, Input } from 'antd';
import moment from 'moment-timezone';
import { UserOutlined, SettingOutlined, PieChartOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd/es/form';
import CalendarHeader from "./CalendarHeader"

const { TabPane } = Tabs;
const { Meta } = Card;

let nameNewCalendar = "basic";
let currentCalendarName = "";
let calendarAliasName = "";
let calendarUrl = "";
let calendarAliasUrl = "";
let currentCalendarId = "";
let currentCalendarSetting = {};
var monthLabels = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
var weekLabels = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
var weekDisplayLabels = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const attendees = [];
const optionalAttendees = [];
const timezoneFontProps = { fontSize: 12, fontFamily: "Arial Black" }
const calendarMonthFontProps = { fontSize: 20, fontFamily: "Arial Black" }
const calendarHourRuleProps = { strokeColor: '#696969', strokeWidth: 1 };

const canvasWidth = 1090;
const millisInMinute = 60000;
const timelineWidth = 40;
const secondTimelineWidth = 80;
const secondTimelineStart = 10;
const weekBlockWidth = 150;
const fullDayHeight = 2400;
const defaultDaySlice = 900000;
let slicerMillis = defaultDaySlice;
const timeBetweenBooking = 100000; //todo
let timezoneRuler = {};
let currentUserId = 0;

const userDefaultWorkingHours = {
    "0": { "work": 0, "start": 540, "end": 1080, "day": "0" },
    "1": { "work": 1, "start": 540, "end": 1080, "day": "1" },
    "2": { "work": 1, "start": 540, "end": 1080, "day": "2" },
    "3": { "work": 1, "start": 540, "end": 1080, "day": "3" },
    "4": { "work": 1, "start": 540, "end": 1080, "day": "4" },
    "5": { "work": 1, "start": 540, "end": 1080, "day": "5" },
    "6": { "work": 0, "start": 540, "end": 1080, "day": "6" },
};

let userWorkingHours = userDefaultWorkingHours;

const { Header, Content, Footer } = Layout;
var calendarPros = {
    "start_at": '2021-10-28T09:00:00-07:00',
    "end_at": '2021-10-28T10:00:00-07:00',
    "name": "Testing",
    "attendees": [],
    "optional-attendees": [],
    "description": "Testing",
    "conference": "Chennai",
    "location": "Vadapalani",
    "payment": "Cool"
};


const { Option } = Select;

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};


const timezoneSelect = [];
const timezoneList = moment.tz.names();
for (let i = 0; i < timezoneList.length; i++) {
    timezoneSelect.push(<Option key={timezoneList[i]}>{timezoneList[i]}</Option>);
}

class Setting extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            counter: 0,
            booking: false,
            visible: false,
            screenWidth: 0,
            screenHeight: 30000,
            slicerMillis: defaultDaySlice,
            earlyBookings: [],
            showTimeGrid: "none",
            userCalendars: [],
            userCalendarsById: [],
            userWorkingHours: [],
            calendarUrl: "",
            calendarAliasUrl: ""
        };
    }

    loginGmail = () => {
        window.location.href = "https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?client_id=598724566440-6i7u41lq5laufklau2umcnspllb4b92b.apps.googleusercontent.com&redirect_uri=https://asia-east2-plannerhug.cloudfunctions.net/google-auth-redirect&scope=https://www.googleapis.com/auth/calendar+https://www.googleapis.com/auth/userinfo.profile&response_type=code&access_type=offline&prompt=consent"
    }



    timezoneChangeHandle = (userPreferenceHours) => {
        if (userPreferenceHours.length == 0) return;
        timezoneRuler = {};
        userPreferenceHours.forEach(eachTimezone => {
            const selectedOffset = moment().tz(eachTimezone).format('lll');
            const browserOffset = moment().tz(moment.tz.guess()).format('lll');
            const diffOffset = -(new Date(browserOffset) - new Date(selectedOffset)) / millisInMinute;
            console.log(selectedOffset, browserOffset, diffOffset);
            timezoneRuler[diffOffset] = { "abbr": eachTimezone };
        });
        console.log(timezoneRuler);
    }

    onFinish = () => {
        message.success('Submit success!');
    };

    onFinishFailed = () => {
        message.error('Submit failed!');
    };


    handleTimeSlicer = (value) => {
        console.log(value);
        slicerMillis = (value*1);
    }


    onMouseDown = (event) => {
        console.log(event);
        this.setState({ visible: true });
        console.log('You pressed the mouse!');
    }

    onMouseDrag = (event) => {
        console.log('You dragged the mouse!');
    }

    utcMillisToBrowser(utcMillis) {
        return (utcMillis - (new Date().getTimezoneOffset() * millisInMinute));
    }

    browserMillisToUtc(browserMillis) {
        return (browserMillis + (new Date().getTimezoneOffset() * millisInMinute));
    }

    onMouseUp = (event) => {
        calendarPros["start"] = this.browserMillisToUtc(event.target.data["start"]);
        calendarPros["start_at"] = new Date(calendarPros["start"]);
        calendarPros["end"] = this.browserMillisToUtc(event.target.data["end"]);
        calendarPros["end_at"] = new Date(calendarPros["end"])
        console.log(calendarPros);
        this.setState({ visible: true });
        console.log(this.state.visible);
        this.setState({ counter: new Date().getTime() });
        console.log('You released the mouse!');
    }

    showModal = () => {
        this.setState({ visible: true });
    };

    formValueChange = (e) => {
        console.log(e.target.name);
        calendarPros[e.target.name] = e.target.value;
    }
    addAttendees = (attendees, optional) => {
        calendarPros["attendees"] = [];
        attendees.forEach(eachAttendee => {
            calendarPros["attendees"].push({ "email": eachAttendee, "optional": optional });
        });
    }
    addOptionalAttendees = (optionalAttendees, optional) => {
        calendarPros["optional-attendees"] = [];
        optionalAttendees.forEach(eachAttendee => {
            calendarPros["optional-attendees"].push({ "email": eachAttendee, "optional": optional });
        });
        console.log(calendarPros["optional-attendees"]);
    }

    cancelNewCalendar = () => {
        this.setState({ visible: false });
    };

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions)
    }

    updateWindowDimensions() {
        console.log(window.innerWidth);
        this.setState({ screenWidth: window.innerWidth });
    }

    processEarlyBooking = (queryPromise) => {
        const earlyBookings = [];
        queryPromise.then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                var calenProps = doc.data();
                calenProps["start"] = this.utcMillisToBrowser(calenProps["start"]);
                calenProps["end"] = this.utcMillisToBrowser(calenProps["end"]);
                earlyBookings.push(calenProps);
            });
            this.setState({ "earlyBookings": earlyBookings });
            console.log(earlyBookings);
        });
    }

    findAuthUserCalendars = (queryPromise) => {
        const userCalendars = [];
        const userCalendarsById = {};
        queryPromise.then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                var calenProps = doc.data();
                calenProps["id"] = doc.id;
                userCalendars.push(calenProps);
                userCalendarsById[doc.id] = calenProps;
            });
            this.setState({ "userCalendars": userCalendars, "userCalendarsById": userCalendarsById });
            console.log(userCalendars);
        });
    }



    componentDidMount() {
        const firebaeAuth = getAuth();
        onAuthStateChanged(firebaeAuth, (currentUser) => {
            console.log(currentUser);
            if (currentUser) {
                currentUserId = currentUser.uid;
                this.setState({ "currentUserProps": currentUser });
                const findAuthUserCalendarsPromise = firebaseApp.findAuthUserCalendars(currentUserId);
                this.findAuthUserCalendars(findAuthUserCalendarsPromise);
            }
        });
    }

    onFinish = () => {

    }

    onFinishFailed = () => {

    }

    setUserTime = (day, position, e) => {
        console.log(position);
        if (userWorkingHours[day] == undefined) {
            userWorkingHours[day] = { "start": 540, "end": 1080, "work": 1, "day": day };
        }
        if (e == null) { return; }
        var minutes = (e.hours() * 60);
        minutes += e.minutes();
        userWorkingHours[day][position] = minutes;
        console.log(userWorkingHours);
    }

    setUserWorkingDays = (day, e) => {
        if (userWorkingHours[day] == undefined) {
            userWorkingHours[day] = { "start": 540, "end": 1080, "work": 1, "day": day };
        }

        if (e.target.checked) {
            userWorkingHours[day]["work"] = 1;
        }
        else {
            userWorkingHours[day]["work"] = 0;
        }
        console.log(userWorkingHours);
    }

    saveCalendarSetting = () => {
        currentCalendarSetting["time_grid"] = userWorkingHours;
        console.log(currentCalendarSetting);
        currentCalendarSetting["slicer_millis"] = slicerMillis;
        firebaseApp.saveCalendarSetting(currentCalendarSetting);
    }

    deleteCalendarById = () => {
        const deletePromise = firebaseApp.deleteCalendarById(currentCalendarId);
        deletePromise.then((querySnapshot) => {
            window.location.href = window.location.href;
        });
    }


    nameNewCalendar = (e) => {
        console.log(e.target.value)
        nameNewCalendar = e.target.value;
    }

    setAliasCalendar = (e) => {
        console.log(e.target.value)
        calendarAliasName = e.target.value;
        calendarAliasUrl = window.location.origin + "/calendar/" + calendarAliasName
        this.setState({ "calendarAliasUrl": calendarAliasUrl });
    }

    saveCalendarAlias = () => {
        console.log(currentCalendarId, calendarAliasName);
        const renamePromise = firebaseApp.setCalendarAlias(currentCalendarId, calendarAliasName);
        renamePromise.then((querySnapshot) => {
            window.location.href = window.location.href;
        });
    }

    saveNewCalendar = (e) => {
        console.log(e.target.value)
        var fuzzyId = nameNewCalendar.toLowerCase().trim();
        fuzzyId = fuzzyId.replace(/ +/g, '-');
        currentCalendarSetting = {};
        const savePromise = firebaseApp.saveNewCalendar({ "name": nameNewCalendar, "fuzzy_id": fuzzyId, "active":1, "calendar":"google" });//todo get dynamically the calendar
        savePromise.then(result => {
            console.log(result)
            window.location.href = window.location.href;
        });
    }

    onChangeCalendar = (value) => {
        console.log(`selected ${value}`);
        currentCalendarId = value;
        currentCalendarSetting = {};
        if (this.state.userCalendarsById[value] == undefined) this.state.userCalendarsById[value] = {};
        currentCalendarSetting = this.state.userCalendarsById[value];
        if (currentCalendarSetting["time_grid"] == undefined) currentCalendarSetting["time_grid"] = userDefaultWorkingHours;
        userWorkingHours = currentCalendarSetting["time_grid"];
        currentCalendarName = currentCalendarSetting["name"];
        slicerMillis = currentCalendarSetting["slicer_millis"];
        console.log(userWorkingHours);
        for (var prop in userWorkingHours) {
            userWorkingHours[prop]["day"] = prop;
            userWorkingHours[prop]["day_display"] = weekDisplayLabels[prop];
        }
        calendarUrl = window.location.origin + "/calendar/" + currentCalendarId;
        calendarAliasUrl = window.location.origin + "/calendar/" + currentCalendarSetting["fuzzy_id"];
        this.setState({ "showTimeGrid": "block", "userWorkingHours": userWorkingHours, "calendarUrl": calendarUrl, "calendarAliasUrl": calendarAliasUrl });
    }


    onSearch = (val) => {
        console.log('search:', val);
    }

    render() {
        const { register, handleSubmit, errors } = this.props;

        return (
            <Layout className="layout" style={{ backgroundColor: "#FFFFFF", minHeight: "100vh" }}>
                <CalendarHeader />

                <Content style={{ marginLeft: "auto", marginRight: "auto", width: "860" }}>

                    <Tabs defaultActiveKey="1" style={{ color: "red" }} type="card">
                        <TabPane tab={'Link'} key={'which-calendar'}>
                            <Button onClick={(e) => this.loginGmail()} icon={<i className="fas fa-plus"></i>} size={5} >Gmail</Button>
                            <Button onClick={(e) => this.loginMicrosoft()} icon={<i className="fas fa-plus"></i>} size={5} >Microsoft</Button>
                            <Button onClick={(e) => this.loginApple()} icon={<i className="fas fa-plus"></i>} size={5} >Apple</Button>
                            <Button onClick={(e) => this.loginApple()} icon={<i className="fas fa-plus"></i>} size={5} >Techiehug</Button>
                        </TabPane>

                        {/*<TabPane tab={'Integrations'} key={'integrations'}>

                            <Card
                                hoverable
                                style={{ width: 240 }}
                                cover={<img alt="example" src="https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png" />}
                            >
                                <Meta title="Europe Street beat" description="www.instagram.com" />
                            </Card>

        </TabPane>*/}

                        <TabPane tab={'Calendars'} key={'calendar-settings'}>

                            <Card>
                                <label>Create a new calendar</label>
                                <Input.Group compact>
                                    <Input style={{ width: "200px" }} onChange={(e) => this.nameNewCalendar(e)} defaultValue="basic" />
                                    <Button type="primary" onClick={(e) => this.saveNewCalendar(e)} >Create</Button>
                                </Input.Group>
                            </Card>

                            <Card style={((this.state.userCalendars.length > 0) ? { display: "block" } : { display: "none" })}>
                                <label>Customize the calendar</label>
                                <Input.Group compact>
                                    <Select
                                        style={{ width: 120 }}
                                        showSearch
                                        placeholder="Select a person"
                                        optionFilterProp="children"
                                        onChange={this.onChangeCalendar}
                                        onSearch={this.onSearch}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {this.state.userCalendars.map(item => (
                                            <Select.Option key={item.id} value={item.id}>
                                                {item.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Input.Group>


                                <div style={{ marginTop: "50pt", "display": this.state.showTimeGrid }}>

                                <div style={{ marginTop: "50pt" }}>
                                    <label>Dice the Day</label>
                                    <Input.Group compact>
                                        <Select
                                            style={{ width: 120 }}
                                            placeholder="Select Hour Blocks"
                                            onChange={this.handleTimeSlicer}
                                        >
                                            <Option value="900000">15 Mins</Option>
                                            <Option value="1800000">30 Mins</Option>
                                            <Option value="2700000">45 Mins</Option>
                                            <Option value="3600000">1 Hour</Option>
                                            <Option value="7200000">2 Hours</Option>
                                            <Option value="10800000">3 Hours</Option>
                                        </Select>
                                    </Input.Group>
                                </div>

                                <div style={{ marginTop: "50pt" }}>
                                    <Row style={{}}>
                                        <Col span={8}>
                                            Working days
                                        </Col>
                                        <Col span={8}>
                                            Start Time
                                        </Col>
                                        <Col span={8}>
                                            End Time
                                        </Col>
                                    </Row>
                                </div>

                                    {Object.keys(this.state.userWorkingHours).map(item => (
                                        <Row key={`days-` + item}>
                                            <Col span={8}>
                                                <Checkbox defaultChecked={userWorkingHours[item]["work"]} onChange={(e => this.setUserWorkingDays(userWorkingHours[item]["day"], e))}>{userWorkingHours[item]["day_display"]}</Checkbox>
                                            </Col>
                                            <Col span={8}>
                                                <TimePicker allowClear={false} format={'HH:mm'} defaultValue={moment((userWorkingHours[item]["start"] / 60) + ":00", 'HH:mm')} onChange={(e => this.setUserTime(0, "start", e))} />
                                            </Col>
                                            <Col span={8}>
                                                <TimePicker allowClear={false} format={'HH:mm'} defaultValue={moment((userWorkingHours[item]["end"]) + ":00", 'HH:mm')} onChange={(e => this.setUserTime(0, "end", e))} />
                                            </Col>
                                        </Row>
                                    ))}

                                    <Button type="primary" onClick={(e => this.saveCalendarSetting())} htmlType="button">Save</Button>

                                    <div style={{ marginTop: "50pt" }}>
                                        <p><a target="_blank" href={calendarAliasUrl}>{calendarAliasUrl}</a></p>
                                    </div>

                                    <Input.Group compact>
                                        <Input style={{ width: "200px" }} onChange={(e) => this.setAliasCalendar(e)} />
                                        <Button type="primary" onClick={(e) => this.saveCalendarAlias(e)} >Rename</Button>
                                    </Input.Group>

                                    <div style={{ marginTop: "50pt" }}>
                                        <Row>
                                        <Col span={8}>
                                            <Button type="primary" danger onClick={(e => this.deleteCalendarById())} htmlType="button">Archive</Button>
                                        </Col>
                                        <Col span={8} offset={8}>
                                            <Button type="primary" danger onClick={(e => this.deleteCalendarById())} htmlType="button">Delete</Button>
                                        </Col>
                                    </Row>
                                    </div>
                                </div>
                            </Card>

                        </TabPane>
                    </Tabs>


                </Content>

                <Footer style={{ textAlign: 'center' }}>Copyright © Techiehug. All Rights Reserved</Footer>
            </Layout>
        )
    }
}

export default Setting;
