import { initializeApp } from 'firebase/app';
import { getFirestore, addDoc, collection, query, where, getDocs, getDoc, doc, setDoc, orderBy, limit, updateDoc, deleteDoc, documentId, serverTimestamp } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from "firebase/auth";


const settings = { timestampsInSnapshots: true };

const firebaseConfig = {
  apiKey: "AIzaSyAfulCoQ_eqtV_4GaTXpNqCxumLPY68qoU",
  authDomain: "plannerhug.firebaseapp.com",
  projectId: "plannerhug",
  storageBucket: "plannerhug.appspot.com",
  messagingSenderId: "598724566440",
  appId: "1:598724566440:web:15b161d4cda3bbc6c4519d",
  measurementId: "G-DL354EFCW4"
};

// Initialize Firebase
const firebaseApplication = initializeApp(firebaseConfig);
const firestoreApp = getFirestore(firebaseApplication);
const firebaseAuthApp = getAuth(firebaseApplication);
//firebase.firestore().settings(settings);


const bookingRef = collection(firestoreApp, "bookings");
const productsRef = collection(firestoreApp, "products");
const storesRef = collection(firestoreApp, "stores");
const userStoresRef = collection(firestoreApp, "user_stores");
const userOrdersRef = collection(firestoreApp, "user_orders");
const storeUsersRef = collection(firestoreApp, "store_users");
const userCartRef = collection(firestoreApp, "store_users");
const calendarUserProfileRef = collection(firestoreApp, "calendar_user_profiles");
const calendarAuthTokenRef = collection(firestoreApp, "calendar_auth_tokens");
const userAuthTokens = collection(firestoreApp, "user_auth_tokens");
const userCalendarSettingRef = collection(firestoreApp, "user_calendar_settings");


export default class firebaseApp {

  constructor() {
    console.log("Firebase initialized");
  }


  //firebase.firestore().collection("user_carts").where("user_id", '==', userId).where("product_id", '==', productId).where("order_id", '==', 0).get().then((querySnapshot) => {
  static findUserCartItemByProductIdAndOrderId = async (userId, productId, orderId) => {
    const firebaseQuery = query(userCartRef, where("user_id", '==', userId), where("product_id", '==', productId), where("order_id", '==', orderId));
    return await getDocs(firebaseQuery);
  }

  //firebase.firestore().collection("user_carts").doc(selectedProduct["cart_id"]).delete();
  static deleteUserCartById = async (cartId) => {
    await deleteDoc(doc(firestoreApp, "user_carts", cartId));
  }


  static deleteCalendarById = async(currentCalendarId) => {
    await deleteDoc(doc(firestoreApp, "user_calendar_settings", currentCalendarId));    
  }


  //firebase.firestore().collection("products").where("show","==",1).orderBy("ord").limit(window.productLength).onSnapshot(this.fetchAllProducts);
  static findAllProducts = async (recordLength) => {
    const firebaseQuery = query(productsRef, where("show", "==", 1), orderBy("ord"), limit(recordLength));
    return await getDocs(firebaseQuery);
  }

  static findProductsByStoreId = async (storeId) => {
    const firebaseQuery = query(productsRef, where("store_id", "==", storeId), where("show", "==", 1), orderBy("ord"), limit(1000));
    return await getDocs(firebaseQuery);
  }

  static findProductsByIdIn = async (findProductsByIdsIn) => {
    const firebaseQuery = query(productsRef, where(documentId(), "in", findProductsByIdsIn));
    return await getDocs(firebaseQuery);
  }

  static findAllStores = async (recordLength) => {
    const firebaseQuery = query(storesRef, where("show", "==", 1), orderBy("ord"), limit(recordLength));
    return await getDocs(firebaseQuery);
  }

  static findUserCartDetailsByOrderId = async (userId, orderId) => {
    const firebaseQuery = query(userCartRef, where("user_id", "==", userId), where("order_id", "==", orderId), limit(100));
    return await getDocs(firebaseQuery);
  }

  static findUserCartByUserIdAndProductIdAndOrderId = async (userId, findProductsById, orderId) => {
    const firebaseQuery = query(userCartRef, where("user_id", '==', userId), where("product_id", "==", findProductsById), where("order_id", "==", orderId));
    return await getDocs(firebaseQuery);
  }




  static findUserStoresByUserId = async (userId) => {
    const firebaseQuery = query(userStoresRef, where("user_id", "==", userId), limit(100));
    return await getDocs(firebaseQuery);
  }

  static findOwnStoresByUserId = async (userId) => {
    const firebaseQuery = query(storesRef, where("user_id", "==", userId), limit(100));
    return await getDocs(firebaseQuery);
  }

  static findStoreOrderByStoreId = async (storeId) => {
    const firebaseQuery = query(userOrdersRef, where("store_id", "==", storeId), limit(100));
    return await getDocs(firebaseQuery);
  }

  static findStoresByPincode = async (pincode) => {
    const firebaseQuery = query(storesRef, where("pincode", "==", pincode), limit(100));
    return await getDocs(firebaseQuery);
  }

  static findStoresByPincodeAndAreaName = async (pincode, areaName) => {
    const firebaseQuery = query(storesRef, where("pincode", "==", pincode), where("area_name", "==", areaName), limit(100));
    return await getDocs(firebaseQuery);
  }

  static findStoresByAreaName = async (areaName) => {
    const firebaseQuery = query(storesRef, where("area_name", "==", areaName), limit(100));
    return await getDocs(firebaseQuery);
  }



  static findUserOrderByUserId = async (userId) => {
    const firebaseQuery = query(userOrdersRef, where("user_id", "==", userId), orderBy("created_at", "desc"), limit(100));
    return await getDocs(firebaseQuery);
  }


  static findStoresByIdIn = async (storeIds) => {
    const firebaseQuery = query(storesRef, where(documentId(), "in", storeIds), limit(100));
    return await getDocs(firebaseQuery);
  }

  static findStores = async () => {
    const firebaseQuery = query(storesRef, where("show", "==", 1), orderBy("store_name",), limit(1000));
    return await getDocs(firebaseQuery);
  }


  static findServerTime = () => {
    return serverTimestamp();
  }

  static findUserCartsByOrderIdIn = async (userId, orderIds) => {
    const firebaseQuery = query(userCartRef, where("user_id", '==', userId), where("order_id", "in", orderIds), orderBy("created_at", "desc"), limit(1000));
    return await getDocs(firebaseQuery);
  }

  static getCartById = async (cartId) => {
    const firebaseQuery = query(doc(firestoreApp, "user_carts", cartId));
    return await getDoc(firebaseQuery);
  }

  static updateCartById = async (docId, docData) => {
    await updateDoc(doc(firestoreApp, "user_carts", docId), docData);
  }

  static saveUserCarts = async (docId, docData) => {
    await setDoc(doc(firestoreApp, "user_carts", docId), docData);
  }

  static saveUserOrder = async (docId, docData) => {
    await setDoc(doc(firestoreApp, "user_orders", docId), docData);
  }

  static updateUserOrder = async (docId, docData) => {
    await updateDoc(doc(firestoreApp, "user_orders", docId), docData);
  }

  static updateUserOrderStatus = async (docId, docData) => {
    await updateDoc(doc(firestoreApp, "user_order_status", docId), docData);
  }

  static saveUserOrderStatus = async (docId, docData) => {
    await setDoc(doc(firestoreApp, "user_order_status", docId), docData);
  }

  static saveBooking = async (docData) => {
    docData["created_at"] = serverTimestamp();
    docData["updated_at"] = serverTimestamp();
    return await setDoc(doc(firestoreApp, "bookings", "a" + new Date().getTime()), docData);
  }

  static saveUserAuthDetails = async (userId, docData) => {
    docData["created_at"] = serverTimestamp();
    docData["updated_at"] = serverTimestamp();
    docData["user_id"] = userId;
    return await setDoc(doc(firestoreApp, "user_auth_tokens", userId), docData, { merge: true });
  }

  static saveUserProfileDetails = async (userId, docData) => {
    docData["created_at"] = serverTimestamp();
    docData["updated_at"] = serverTimestamp();
    return await setDoc(doc(firestoreApp, "calendar_user_profiles", userId), docData, { merge: true });
  }



  static setCalendarAlias = async (calendarId, aliasName) => {
    const docData = {"fuzzy_id": aliasName};
    await updateDoc(doc(firestoreApp, "user_calendar_settings", calendarId), docData);
  }


  
  static updateDocById = (documentId, collectionName, updateProps) => {
    updateDoc(doc(firestoreApp, collectionName, documentId), updateProps);
  }

  static linkRedirectCodeWithUser = async (userId, calendarLinkCode) => {
    const findByRedirectLinkCodeQuery = query(calendarAuthTokenRef, where("fuzzy_id", "==", calendarLinkCode), limit(1));
    const querySnapshot = await getDocs(findByRedirectLinkCodeQuery);
    querySnapshot.forEach((doc) => {
      this.updateDocById(doc.id, "calendar_auth_tokens", { "fuzzy_id": 1, "uid": userId, "user_id": userId, "updated_at": serverTimestamp() });
    });

    const firebaseRedirectQuery = query(calendarUserProfileRef, where("fuzzy_id", "==", calendarLinkCode), limit(1));
    const queryRedirectSnapshot = await getDocs(firebaseRedirectQuery);
    queryRedirectSnapshot.forEach((doc) => {
      this.updateDocById(doc.id, "calendar_user_profiles", { "fuzzy_id": 1, "uid": userId, "user_id": userId, "updated_at": serverTimestamp() });
    });

  }


  static findBookings = async (userId, orderIds) => {
    const firebaseQuery = query(bookingRef, orderBy("created_at"), limit(1000));
    return await getDocs(firebaseQuery);
  }

  
  static findCalendarById = async (calendarId) => {
    const firebaseQuery = query(userCalendarSettingRef, where("fuzzy_id", "==", calendarId), limit(1));
    return await getDocs(firebaseQuery);
  }
  
  static findCalendarByFuzzyId = async (calendarFuzzyId) => {
    const firebaseQuery = query(userCalendarSettingRef, where("fuzzy_id", "==", calendarFuzzyId), limit(1));
    return await getDocs(firebaseQuery);
  }
  

  static calendarExistence = async (calendarUser) => {
    const firebaseQuery = query(userCalendarSettingRef, where("username", "==", calendarUser), limit(10));
    return await getDocs(firebaseQuery);
  }

  static findAuthUserCalendars = async (currentUserId) => {
    const firebaseQuery = query(userCalendarSettingRef, where("user_id", "==", currentUserId), limit(10));
    return await getDocs(firebaseQuery);
  }


  static getCurrentUser = () => {
    return firebaseAuthApp.currentUser
  }

  static saveCalendarSetting = async (docData) => {
    docData["user_id"] = this.getCurrentUser().uid;
    if(docData["calendar_id"]!==undefined)
    {
      await setDoc(doc(firestoreApp, "user_calendar_settings", docData["calendar_id"]), docData);
    }
    else
    {
      await addDoc(collection(firestoreApp, "user_calendar_settings"), docData);
    }
  }

  static saveNewCalendar = async (docData) => {
    docData["user_id"] = this.getCurrentUser().uid;
    await addDoc(collection(firestoreApp, "user_calendar_settings"), docData);
  }


}